.contact-section {
  padding: 50px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.contact-heading {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #4a90e2;
}

.contact-text {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.contact-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-bottom: 40px;
}

.contact-item {
  background: #1d1d1d;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 250px;
}

.contact-item h2 {
  color: #4a90e2;
  margin-bottom: 10px;
}

.contact-item button {
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 4px;
}

.contact-form button {
  background-color: #6c63ff;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


/* Cards */


.services-section {
  padding: 50px;
  text-align: center;
}

.services-heading {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #4a90e2;
}

.services-subheading {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.cards-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

.card {
  background: #1d1d1d !important;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

.icon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #4a90e2;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #58a6ff !important;
  /* color: white; */

}

.card-description {
  font-size: 1rem;
  margin-bottom: 20px;
  color: white;
}

.card-button {
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}
